import api from './api'

export default {
  termalMap(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/castrations-in-map', { params })
  },

  pointMap(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/castrations-point-map', { params })
  },

  clinicTime(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/castrations-clinic-time', { params })
  },

  castrationsByClinic(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/castrations-by-clinic', { params })
  },

  castrationsAwaitingBatch() {
    return api.get('/partner-licensings/:partnerLicensingId/reports/castrations-awaiting-batch')
  },

  castrationsByMonth(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/castrations-by-month', { params })
  },

  castrationsByBreed(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/castrations-by-breed', { params })
  },

  castrationsByProtectors(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/castrations-by-protectors', { params })
  },

  castrationsByNGO(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/castrations-by-ngo', { params })
  },

  castrationsByCancelationReason(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/castrations-by-cancelation-reason', { params })
  },

  castrationsByRejectionReason(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/castrations-by-rejection-reason', { params })
  },

  castrationsTime(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/castrations-time-by-licensing', { params })
  },

  commentsByClinicsInApprovedCastrations(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/comments-by-clinics-in-approved-castrations', { params })
  },

  animalsInFair(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/animals-in-fair', { params })
  },

  monthlyAdoptionsByFair(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/monthly-adoptions-by-fair', { params })
  },

  animalsInMap(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/animals/animals-in-map', { params })
  },

  animalsPointMap(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/animals/animals-point-map', { params })
  },

  clinicalCareItemClinicBalances(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/clinical-cares/item-clinic-balances', { params })
  },

  castrationsByUser(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/castrations-by-user', { params })
  },

  castrationsByYear(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/castrations-by-year', { params })
  },

  castrationsByCras(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/reports/castrations-by-cras', { params })
  },
}
