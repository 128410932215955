/* eslint-disable react/prop-types */
import { LICENSING_TYPE } from '@/constants/licensingType'
import useAuth from '@/hooks/useAuth'
import About from '@/views/app/About'
import BreadcrumbBatches from '@/views/app/Batches/Status/Breadcrumb'
import BreadcrumbCastrations from '@/views/app/Castrations/Status/Breadcrumb'
import Dashboard from '@/views/app/Dashboard'
import BreadcrumbNgos from '@/views/app/ngos/Breadcrumb'
import BreadcrumbPotectors from '@/views/app/protectors/Breadcrumb'
import React, { useMemo } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { NotFound } from '@/components'
import CompleteAccountContainer from '../components/CompleteAccountContainer'
import AdoptionFairsRoutes from './adoptionFairs'
import AdoptionsRequestedRoutes from './adoptionsRequested'
import BatchesRoutes from './batches'
import CastrationsRoutes from './castrations'
import ClinicalCaresRoutes from './clinicalCares'
import ClinicsRoutes from './clinics'
import Sidebar from './components/Sidebar'
import ManualAdoptionsRoutes from './manualAdoptions'
import MistreatmentsRoutes from './mistreatments'
import NgosRoutes from './ngos'
import PartnerBlacklistsRoutes from './partnerBlacklists'
import PetisUsersRoutes from './petisUsers'
import Pets from './pets'
import ProtectorsRoutes from './protectors'
import ReportsRoutes from './reports'
import SettingsRoutes from './settings'

function App() {
  const { path } = useRouteMatch()
  const { licensing, user } = useAuth()

  const allRoutes = [
    {
      key: 'dashboard',
      exact: true,
      breadcrumb: 'Dashboard',
      icon: 'fas fa-tachometer-alt',
      path: '/app',
    },
    {
      key: 'castrations',
      breadcrumb: 'Castrações',
      icon: 'fas fa-cut',
      path: '/app/castrations',
      permission: LICENSING_TYPE.CASTRATION_PROVIDER,
    },
    {
      key: 'mistreatments',
      icon: 'fas fa-bullhorn',
      breadcrumb: 'Maus tratos',
      path: '/app/mistreatments',
      permission: LICENSING_TYPE.MISTREATMENT,
    },
    {
      key: 'mistreatmentsShow',
      icon: 'fas fa-bullhorn',
      breadcrumb: ({ match }) => <span>Denúncia #{match?.params?.id}</span>,
      path: '/app/mistreatments/show/:id',
      hide: true,
      permission: LICENSING_TYPE.MISTREATMENT,
    },
    {
      key: 'mistreatmentsCreate',
      icon: 'fas fa-bullhorn',
      breadcrumb: 'Nova denúncia',
      path: '/app/mistreatments/form/add',
      hide: true,
      permission: LICENSING_TYPE.MISTREATMENT,
    },
    {
      key: 'clinicalCares',
      breadcrumb: 'Atendimentos',
      icon: 'fas fa-stethoscope',
      permission: LICENSING_TYPE.CLINICAL_CARE,
      children: [
        {
          key: 'clinicalCaresList',
          breadcrumb: 'Atendimentos',
          icon: 'fas fa-stethoscope',
          path: '/app/clinical-cares/list',
          exact: true,
          permission: LICENSING_TYPE.CLINICAL_CARE,
        },
        {
          key: 'clinicalCaresClinics',
          breadcrumb: 'Clínicas',
          icon: 'fas fa-clinic-medical',
          path: '/app/clinical-cares/clinics',
          exact: true,
          permission: LICENSING_TYPE.CLINICAL_CARE,
        },
        {
          key: 'clinicalCaresClinicsItems',
          breadcrumb: 'Gerenciar itens da clínica',
          path: '/app/clinical-cares/clinics/:id/items',
          exact: true,
          hide: true,
          permission: LICENSING_TYPE.CLINICAL_CARE,
        },
      ],
    },
    {
      key: 'clinicalCaresCreate',
      breadcrumb: 'Novo atendimento',
      icon: 'fas fa-stethoscope',
      path: '/app/clinical-cares/form/add',
      hide: true,
      permission: LICENSING_TYPE.CLINICAL_CARE,
    },

    {
      key: 'clinicalCaresShow',
      breadcrumb: ({ match }) => <span>Atendimento #{match?.params?.id}</span>,
      path: '/app/clinical-cares/show/:id',
      exact: true,
      hide: true,
    },
    {
      key: 'pets',
      breadcrumb: 'Pets',
      icon: 'fas fa-paw',
      path: '/app/pets',
    },
    {
      key: 'fairs',
      breadcrumb: 'Feiras de adoção',
      icon: 'fas fa-heart',
      path: '/app/adoption-fairs',
      permission: LICENSING_TYPE.FAIRS_MANAGER,
    },
    {
      key: 'adoptionsRequestedShow',
      breadcrumb: 'Solicitação de adoção',
      path: '/app/adoption-requested/:id',
      hide: true,
    },
    {
      key: 'manual-adoptions',
      breadcrumb: 'Adoções manuais',
      icon: 'fas fa-dog',
      path: '/app/manual-adoptions',
    },
    {
      key: 'manualAdoptionCreate',
      exact: true,
      breadcrumb: 'Nova adoção',
      path: '/app/manual-adoptions/form/create',
      hide: true,
    },
    {
      key: 'manualAdoptionShow',
      breadcrumb: ({ match }) => <span>Adoção #{match?.params?.id}</span>,
      path: '/app/manual-adoptions/:id',
      exact: true,
      hide: true,
    },
    {
      key: 'fair',
      breadcrumb: ({ match }) => <span>Feira #{match?.params?.id}</span>,
      icon: 'fas fa-paw',
      path: '/app/adoption-fairs/:id',
      hide: true,
      permission: LICENSING_TYPE.FAIRS_MANAGER,
    },
    {
      key: 'castrationsShow',
      breadcrumb: ({ match }) => <span>Castração #{match?.params?.id}</span>,
      path: '/app/castrations/status/:status/:id',
      hide: true,
      permission: LICENSING_TYPE.CASTRATION_PROVIDER,
    },
    {
      key: 'castrationsStatus',
      breadcrumb: BreadcrumbCastrations,
      path: '/app/castrations/status/:status',
      hide: true,
      permission: LICENSING_TYPE.CASTRATION_PROVIDER,
    },
    {
      key: 'clinics',
      icon: 'fas fa-clinic-medical',
      breadcrumb: 'Clínicas(castração)',
      permission: LICENSING_TYPE.CASTRATION_PROVIDER,
      children: [
        {
          key: 'clinicsList',
          breadcrumb: 'Licenciadas',
          icon: 'fas fa-check',
          path: '/app/clinics/approved',
        },
        {
          key: 'clinicsRequestList',
          breadcrumb: 'Solicitações',
          icon: 'fas fa-question',
          path: '/app/clinics/requested',
        },
        {
          key: 'clinicsShow',
          breadcrumb: ({ match }) => <span>Clínica #{match?.params?.id}</span>,
          path: '/app/clinics/:status/:id',
          hide: true,
        },
      ],
    },
    // {
    //   key: 'news',
    //   breadcrumb: 'Notícias',
    //   icon: 'fas fa-fa-fw fa-newspaper',
    //   path: '/app/news',
    // },
    // {
    //   key: 'newsShow',
    //   breadcrumb: 'Artigo',
    //   icon: 'fas fa-fa-fw fa-newspaper',
    //   path: '/app/news/:id',
    //   hide: true,
    // },
    // {
    //   key: 'newsCreate',
    //   breadcrumb: 'Novo artigo',
    //   icon: 'fas fa-fa-fw fa-newspaper',
    //   path: '/app/news/form/create',
    //   hide: true,
    // },
    {
      key: 'protectors',
      icon: 'fas fa-hand-holding-heart',
      breadcrumb: 'Protetores',
      children: [
        {
          key: 'protectorsList',
          breadcrumb: 'Licenciados',
          icon: 'fas fa-check',
          path: '/app/protectors/approved',
        },
        {
          key: 'protectorsRequestList',
          breadcrumb: 'Solicitações',
          icon: 'fas fa-question',
          path: '/app/protectors/requested',
        },
        {
          key: 'protectorStatus',
          breadcrumb: BreadcrumbPotectors,
          path: '/app/protectors/:status',
          hide: true,
        },
      ],
    },
    {
      key: 'ngos',
      icon: 'fas fa-building',
      breadcrumb: 'ONGs',
      children: [
        {
          key: 'ngosList',
          breadcrumb: 'Licenciadas',
          icon: 'fas fa-check',
          path: '/app/ngos/approved',
        },
        {
          key: 'ngosRequestList',
          breadcrumb: 'Solicitações',
          icon: 'fas fa-question',
          path: '/app/ngos/requested',
        },
        {
          key: 'ngosStatus',
          breadcrumb: BreadcrumbNgos,
          path: '/app/ngos/:status',
          hide: true,
        },
      ],
    },
    {
      key: 'batches',
      breadcrumb: 'Lotes',
      icon: 'fas fa-box',
      path: '/app/batches',
      permission: LICENSING_TYPE.CASTRATION_PROVIDER,
    },
    {
      key: 'petsShow',
      breadcrumb: 'Histórico do pet',
      icon: 'fas fa-paw',
      path: '/app/pets/:id',
      hide: true,
    },
    {
      key: 'batchesStatus',
      breadcrumb: BreadcrumbBatches,
      path: '/app/batches/status/:status',
      hide: true,
    },
    {
      key: 'batchesShow',
      breadcrumb: ({ match }) => <span>Lote #{match?.params?.id}</span>,
      path: '/app/batches/status/:status/:id',
      hide: true,
    },
    {
      key: 'reports',
      breadcrumb: 'Relatórios',
      icon: 'fas fa-chart-area',
      path: '/app/reports',
    },
    {
      key: 'petisUsers',
      breadcrumb: 'Usuários do Petis',
      icon: 'fas fa-users',
      path: '/app/petis-users',
    },
    {
      key: 'partnerBlacklists',
      breadcrumb: 'Bloqueios',
      icon: 'fas fa-user-slash',
      path: '/app/blacklist',
    },
    {
      key: 'petisUsersShow',
      breadcrumb: 'Histórico do usuário',
      path: '/app/petis-users/:id',
      hide: true,
    },
    {
      key: 'reportsCastrationTermal',
      breadcrumb: 'Mapa térmico de castrações',
      icon: 'fas fa-map',
      path: '/app/reports/castrations-termal-map',
      hide: true,
    },
    {
      key: 'reportsCastrationsAwaitingBatch',
      breadcrumb: 'Castrações aguardando lote',
      icon: 'fas fa-clinic-medical',
      path: '/app/reports/castrations-awaiting-batch',
      hide: true,
    },
    {
      key: 'reportsPointMap',
      breadcrumb: 'Castrações no mapa',
      icon: 'fas fa-fa-fw fa-map-marker-alt',
      path: '/app/reports/castrations-in-map',
      hide: true,
    },
    {
      key: 'reportsClinicTime',
      breadcrumb: 'Média de atendimento',
      icon: 'fas fa-fa-fw fa-stopwatch',
      path: '/app/reports/castrations-clinic-time',
      hide: true,
    },
    {
      key: 'reportsCastrationsTime',
      breadcrumb: 'Tempo das castrações',
      icon: 'fas fa-fa-fw fa-stopwatch',
      path: '/app/reports/castrations-time',
      hide: true,
    },
    {
      key: 'reportsClinicCastrations',
      breadcrumb: 'Castrações por clínica',
      icon: 'fas fa-fa-fw fa-clinic-medical',
      path: '/app/reports/castrations-by-clinic',
      hide: true,
    },
    {
      key: 'reportsMonthCastrations',
      breadcrumb: 'Castrações por mês',
      icon: 'fas fa-fa-fw fa-calendar',
      path: '/app/reports/castrations-by-month',
      hide: true,
    },
    {
      key: 'reportsYearCastrations',
      breadcrumb: 'Castrações por ano',
      icon: 'fas fa-fa-fw fa-hourglass',
      path: '/app/reports/castrations-by-year',
      hide: true,
    },
    {
      key: 'reportsBreedCastrations',
      breadcrumb: 'Castrações por raça',
      icon: 'fas fa-fa-fw fa-paw',
      path: '/app/reports/castrations-by-breed',
      hide: true,
    },
    {
      key: 'reportsProtectorCastrations',
      breadcrumb: 'Castrações por protetor(a)',
      icon: 'fas fa-fa-fw fa-hand-holding-heart',
      path: '/app/reports/castrations-by-protector',
      hide: true,
    },
    {
      key: 'commentsByClinicsInApprovedCastrations',
      breadcrumb: 'Comentários das clínicas por castração aprovada',
      icon: 'fas fa-fa-fw fa-comment',
      path: '/app/reports/comments-by-clinics-in-approved-castrations',
      hide: true,
    },
    {
      key: 'reportsNGOCastrations',
      breadcrumb: 'Castrações por ONG',
      icon: 'fas fa-fa-fw fa-building',
      path: '/app/reports/castrations-by-ngo',
      hide: true,
    },
    {
      key: 'reportsCastrationsByCancelationReason',
      breadcrumb: 'Castrações por motivo de cancelamento',
      icon: 'fas fa-fa-fw fa-times',
      path: '/app/reports/castrations-by-cancelation-reasons',
      hide: true,
    },
    {
      key: 'reportsCastrationsByRejectionReason',
      breadcrumb: 'Castrações por motivo de rejeição',
      icon: 'fas fa-fa-fw fa-ban',
      path: '/app/reports/castrations-by-rejection-reasons',
      hide: true,
    },
    {
      key: 'reportsAnimalsInFair',
      breadcrumb: 'Resumo das feiras de adoção',
      icon: 'fas fa-fa-fw fa-heart',
      path: '/app/reports/animals-in-fair',
      hide: true,
    },
    {
      key: 'reportsMonthlyAdoptionsByFair',
      breadcrumb: 'Adoções mensais por feira',
      icon: 'fas fa-fa-fw fa-heart',
      path: '/app/reports/monthly-adoptions-by-fair',
      hide: true,
    },
    {
      key: 'reportsAnimalsTermalMap',
      breadcrumb: 'Pets no mapa',
      icon: 'fas fa-fa-fw fa-map',
      path: '/app/reports/animals-termal-map',
      hide: true,
    },
    {
      key: 'reportsAnimalsPointMap',
      breadcrumb: 'Pets no mapa',
      icon: 'fas fa-fa-fw fa-marker-alt',
      path: '/app/reports/animals-in-map',
      hide: true,
    },
    {
      key: 'clinicalCareItemClinicBalances',
      breadcrumb: 'Estoque dos itens clínicos',
      icon: 'fas fa-fa-fw fa-star-of-life',
      path: '/app/reports/clinical-care-item-clinic-balances',
      hide: true,
    },
    {
      key: 'castrationsByUser',
      breadcrumb: 'Castrações por usuário',
      icon: 'fas fa-fa-fw fa-cut',
      path: '/app/reports/castrations-by-user',
      hide: true,
    },
    {
      key: 'reportsCastrationsByCras',
      breadcrumb: 'Castrações solicitadas por usuários com CRAS',
      icon: 'fas fa-fa-fw fa-user-circle',
      path: '/app/reports/castrations-by-cras',
      hide: true,
    },
    {
      key: 'settings',
      icon: 'fas fa-cog',
      breadcrumb: 'Configurações',
      hide: licensing?.licensedId !== user?.id,
      children: [
        {
          key: 'settingsInfo',
          breadcrumb: 'Institucional',
          icon: 'fas fa-info',
          path: '/app/settings/info',
        },
        {
          key: 'settingsUsers',
          breadcrumb: 'Meus usuários',
          icon: 'fas fa-users',
          path: '/app/settings/users',
        },
        {
          key: 'settingsUsersCreate',
          breadcrumb: 'Novo usuário',
          hide: true,
          path: '/app/settings/users/create',
        },
        {
          key: 'settingsParams',
          breadcrumb: 'Parâmetros',
          icon: 'fas fa-cogs',
          path: '/app/settings/params',
        },
        {
          key: 'settingsClinicalCareItems',
          breadcrumb: 'Itens clínicos',
          icon: 'fas fa-star-of-life',
          path: '/app/settings/clinical-care-items',
          permission: LICENSING_TYPE.CLINICAL_CARE,
        },
        {
          key: 'settingsTerms',
          breadcrumb: 'Termos',
          icon: 'fas fa-file-alt',
          path: '/app/settings/terms',
        },
        {
          key: 'settingsNgos',
          breadcrumb: "ONG's",
          icon: 'fas fa-building',
          path: '/app/settings/ngos',
        },
        {
          key: 'settingsProtectors',
          breadcrumb: 'Protetores',
          icon: 'fas fa-hand-holding-heart',
          path: '/app/settings/protectors',
        },
        {
          key: 'settingsAddress',
          breadcrumb: 'Endereço',
          icon: 'fas fa-map-marker-alt',
          path: '/app/settings/address',
        },
        {
          key: 'clinicsShow',
          breadcrumb: ({ match }) => <span>Clínica #{match?.params?.id}</span>,
          path: '/app/clinics/:status/:id',
          hide: true,
        },
      ],
    },
    {
      key: 'about',
      breadcrumb: 'Sobre',
      icon: 'fas fa-info-circle',
      path: '/app/about',
    },
  ]

  const permissions = (licensing?.licenseTypes || []).filter(
    (p) => (licensing?.myPermissions || []).includes(p),
  )

  const routes = useMemo(() => {
    if (!licensing) return []
    return allRoutes.filter((route) => {
      if (!route.permission) return true
      return (permissions || []).includes(route.permission)
    }).map((r) => {
      if (!r.children) return r

      return {
        ...r,
        children: r.children.filter((c) => {
          if (!c.permission) return true
          return (permissions || []).includes(c.permission)
        }),
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licensing])

  return (
    <Sidebar routes={routes}>
      <CompleteAccountContainer>
        <Switch>
          <Route exact path={path}>
            <Dashboard title="Petis - Parceiros / Dashboard" />
          </Route>
          <Route path={`${path}/castrations`}>
            <CastrationsRoutes />
          </Route>
          <Route path={`${path}/clinics`}>
            <ClinicsRoutes />
          </Route>
          <Route path={`${path}/mistreatments`}>
            <MistreatmentsRoutes />
          </Route>
          {/* <Route path={`${path}/news`}>
          <NewsRoutes />
        </Route> */}
          <Route path={`${path}/adoption-fairs`}>
            <AdoptionFairsRoutes />
          </Route>
          <Route path={`${path}/adoption-requested`}>
            <AdoptionsRequestedRoutes />
          </Route>
          <Route path={`${path}/manual-adoptions`}>
            <ManualAdoptionsRoutes />
          </Route>
          <Route path={`${path}/blacklist`}>
            <PartnerBlacklistsRoutes />
          </Route>
          <Route path={`${path}/protectors`}>
            <ProtectorsRoutes />
          </Route>
          <Route path={`${path}/clinical-cares`}>
            <ClinicalCaresRoutes />
          </Route>
          <Route path={`${path}/pets`}>
            <Pets />
          </Route>
          <Route path={`${path}/ngos`}>
            <NgosRoutes />
          </Route>
          <Route path={`${path}/batches`}>
            <BatchesRoutes />
          </Route>
          <Route path={`${path}/reports`}>
            <ReportsRoutes />
          </Route>
          <Route path={`${path}/petis-users`}>
            <PetisUsersRoutes />
          </Route>
          <Route path={`${path}/settings`}>
            <SettingsRoutes />
          </Route>
          <Route path={`${path}/about`}>
            <About />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </CompleteAccountContainer>
    </Sidebar>
  )
}

export default App
